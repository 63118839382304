import { SvgIconProps } from '@material-ui/core';
import React, { FC, forwardRef } from 'react';
import { ReactComponent as MapLocationIconSvg } from './mapLocationIconSvg.svg';
import { ReactComponent as ConstructionIconSvg } from './constructionIcon.svg';
import { ReactComponent as EnergyIconSvg } from './energyIcon.svg';
import { ReactComponent as EngineeringIconSvg } from './engineeringIcon.svg';
import { ReactComponent as TechnologyIconSvg } from './technologyIcon.svg';
import { ReactComponent as WirelessIconSvg } from './wirelessIcon.svg';
import { ReactComponent as LocationIconSvg } from './LocationIconSvg.svg';
import { ReactComponent as ConstructionWhiteIconSvg } from './construction-white-icon.svg';
import { ReactComponent as ConstructionWhiteIconMobileSvg } from './construction-white-icon-mobile.svg';
import { ReactComponent as EnergyWhiteIconSvg } from './energy-white-icon.svg';
import { ReactComponent as EnergyWhiteIconMobileSvg } from './energy-white-icon-mobile.svg';
import { ReactComponent as EngineeringWhiteIconSvg } from './engineering-white-icon.svg';
import { ReactComponent as EngineeringWhiteIconMobileSvg } from './engineering-white-icon-mobile.svg';
import { ReactComponent as TechnologyWhiteIconSvg } from './technology-white-icon.svg';
import { ReactComponent as TechnologyWhiteIconMobileSvg } from './technology-white-icon-mobile.svg';
import { ReactComponent as WirelessWhiteIconSvg } from './wireless-white-icon.svg';
import { ReactComponent as WirelessWhiteIconMobileSvg } from './wireless-white-icon-mobile.svg';
import { ReactComponent as PinIconSvg } from './pin-icon.svg';
import { ReactComponent as WaterIconSvg } from './water-icon.svg';
import { ReactComponent as WaterWhiteIconSvg } from './water-white-icon.svg';
import { ReactComponent as ArrowDownIconSvg } from './arrow-down.svg';

export const MapLocationIcon: FC<SvgIconProps> = forwardRef((props, ref) => <MapLocationIconSvg ref={ref} {...props} />);
export const ConstructionIcon: FC<SvgIconProps> = forwardRef((props, ref) => <ConstructionIconSvg ref={ref} {...props} />);
export const EnergyIcon: FC<SvgIconProps> = forwardRef((props, ref) => <EnergyIconSvg ref={ref} {...props} />);
export const EngineeringIcon: FC<SvgIconProps> = forwardRef((props, ref) => <EngineeringIconSvg ref={ref} {...props} />);
export const TechnologyIcon: FC<SvgIconProps> = forwardRef((props, ref) => <TechnologyIconSvg ref={ref} {...props} />);
export const WirelessIcon: FC<SvgIconProps> = forwardRef((props, ref) => <WirelessIconSvg ref={ref} {...props} />);
export const WaterIcon: FC<SvgIconProps> = forwardRef((props, ref) => <WaterIconSvg ref={ref} {...props} />);
export const LocationIcon: FC<SvgIconProps> = forwardRef((props, ref) => <LocationIconSvg ref={ref} {...props} />);
export const ConstructionWhiteIconMobile: FC<SvgIconProps> = forwardRef((props, ref) => (
  <ConstructionWhiteIconMobileSvg ref={ref} {...props} />
));
export const ConstructionWhiteIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <ConstructionWhiteIconSvg ref={ref} {...props} />
));
export const EnergyWhiteIcon: FC<SvgIconProps> = forwardRef((props, ref) => <EnergyWhiteIconSvg ref={ref} {...props} />);
export const EnergyWhiteIconMobile: FC<SvgIconProps> = forwardRef((props, ref) => (
  <EnergyWhiteIconMobileSvg ref={ref} {...props} />
));
export const EngineeringWhiteIconMobile: FC<SvgIconProps> = forwardRef((props, ref) => (
  <EngineeringWhiteIconMobileSvg ref={ref} {...props} />
));
export const EngineeringWhiteIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <EngineeringWhiteIconSvg ref={ref} {...props} />
));
export const TechnologyWhiteIconMobile: FC<SvgIconProps> = forwardRef((props, ref) => (
  <TechnologyWhiteIconMobileSvg ref={ref} {...props} />
));
export const TechnologyWhiteIcon: FC<SvgIconProps> = forwardRef((props, ref) => <TechnologyWhiteIconSvg ref={ref} {...props} />);
export const WirelessWhiteIcon: FC<SvgIconProps> = forwardRef((props, ref) => <WirelessWhiteIconSvg ref={ref} {...props} />);
export const WirelessWhiteIconMobile: FC<SvgIconProps> = forwardRef((props, ref) => (
  <WirelessWhiteIconMobileSvg ref={ref} {...props} />
));
export const WaterWhiteIcon: FC<SvgIconProps> = forwardRef((props, ref) => <WaterWhiteIconSvg ref={ref} {...props} />);
export const PinIcon: FC<SvgIconProps> = forwardRef((props, ref) => <PinIconSvg ref={ref} {...props} />);
export const ArrowDownIcon: FC<SvgIconProps> = forwardRef((props, ref) => <ArrowDownIconSvg ref={ref} {...props} />);

export { default as constructionMarkIconSvg } from './constructionMarkIcon.svg';
export { default as wirelessMarkIconSvg } from './wirelessMarkIconSvg.svg';
export { default as energyMarkIconSvg } from './energyIconMarkSvg.svg';
export { default as engineeringMarkIconSvg } from './engineeringMarkIconSvg.svg';
export { default as technologyMarkIconSvg } from './technologyMarkIconSvg.svg';
export { default as waterMapIconSvg } from './water-map-icon.svg';
